/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "La web marcianitos.org me acabo de enterar que desapareció, por suerte su contenido no se ha perdido y una nueva comunidad ha nacido en su lugar así que ahora podemos encontrar ", React.createElement(_components.a, {
    href: "http://www.retrovicio.com/",
    title: "RetroVicios"
  }, "RetroVicio"), ", con las mismas ganas de seguir jugando a juegos ", React.createElement(_components.em, null, "viejunos"), " y montar esas maquinas retro que tanto me gustaban, todo un ejemplo de DIY."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
